@import url(https://fonts.googleapis.com/css?family=Gelasio&display=swap);
body {
  margin: 0;
  font-family: 'Gelasio', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  position: relative;
}


.PortfolioDetails {
  background-color: red;
  width: 90vw;
  height: auto;
  position: absolute;
  z-index: 10;
  margin-left: 5vw;
  margin-top: 5vh;
  color: white;
}

.PortfolioNavigation {
  width: 90vw;
  height: auto;
  position: absolute;
  z-index: 11;
  margin-left: 5vw;
  margin-bottom: 5vh;
  color: white;
  bottom: 0px;
}

.portNav {
  background-color: blue;
  width: auto;
  display: inline-block;
  cursor: pointer;
  bottom: 0px;
  padding: 3px;
  text-decoration: none;
  color: white;
}

.navPrevious {
  position: absolute;
  left: 0px;
}

.navNext {
  position: absolute;
  right: 0px;
}

.Menu {
  background-color: white;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
}

.highlightedProject {
  position: absolute;
  width: 100%;
  height: 100px;
  color: #a6a68b;
  bottom: 0;
  text-align: center;
  z-index: 120;
}

.highlightedProject h2 {
  font-weight: normal;
  font-size: 1.6em;
  color: #4d4d3b;
}

.highlightedProject h5 {
  font-weight: normal;
  margin-top: -20px;
}

.loadingStatus {
  position: absolute;
  width: 100%;
  height: 60px;
  color: #4d4d3b;
  bottom: 0;
  text-align: center;
  z-index: 2;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.lsShow {
  opacity: 1;
}

.lsHide {
  opacity: 0;
}

.topTitle {
  position: absolute;
  width: 100%;
  height: 100px;
  color: #a6a68b;
  top: 0;
  text-align: left;
  z-index: 2;
  margin-left: 30px;
}

.topTitle h2 {
  font-weight: normal;
  font-size: 3em;
  color: #4d4d3b;
  line-height: 2em;
}

.topTitle h5 {
  font-weight: normal;
  margin-top: -60px;
}

