.PortfolioNavigation {
  width: 90vw;
  height: auto;
  position: absolute;
  z-index: 11;
  margin-left: 5vw;
  margin-bottom: 5vh;
  color: white;
  bottom: 0px;
}

.portNav {
  background-color: blue;
  width: auto;
  display: inline-block;
  cursor: pointer;
  bottom: 0px;
  padding: 3px;
  text-decoration: none;
  color: white;
}

.navPrevious {
  position: absolute;
  left: 0px;
}

.navNext {
  position: absolute;
  right: 0px;
}
