.PortfolioDetails {
  background-color: red;
  width: 90vw;
  height: auto;
  position: absolute;
  z-index: 10;
  margin-left: 5vw;
  margin-top: 5vh;
  color: white;
}
